import React from "react";
import { ModalFooter, ModalBody, Input, Modal, ModalHeader } from "reactstrap";
import { useStep } from "react-hooks-helper";
import { useTranslation } from "react-i18next";
import NProgress from "nprogress";
import classnames from "classnames";
import swal from "@sweetalert/with-react";
import { StyledButton } from "@aureskonnect/react-ui";
import { isEmailValid } from "../../../helpers";

import { FORGOT_PASSWORD_CODE_LENGTH } from "../../../constants";

import ShowPasswordSvgIcon from "../../../components/common/SvgIcons/ShowPasswordSvgIcon";
import HidePasswordSvgIcon from "../../../components/common/SvgIcons/HidePasswordSvgIcon";
import PasswordStrengthMeter from "./PasswordStrengthMeter";

import "./index.css";

type ForgotPasswordModalType = {
    isModalOpened: boolean;
    toggleOpeningForgotPasswordModal(): void;
    setIsForgotPasswordClick: React.Dispatch<React.SetStateAction<boolean>>;
    initEmail: string;
    setIsInvalidCode: React.Dispatch<React.SetStateAction<boolean>>;
    isInvalidCode: boolean;
    isUnverifiedCode: boolean;
    setIsUnverifiedCode: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ForgotPasswordModal({
    isModalOpened,
    toggleOpeningForgotPasswordModal,
    setIsForgotPasswordClick,
    initEmail,
    setIsInvalidCode,
    isInvalidCode,
    isUnverifiedCode,
    setIsUnverifiedCode,
}: ForgotPasswordModalType) {
    const { t } = useTranslation();
    const [email, setEmail] = React.useState<string>(initEmail);
    const [newPassword, setNewPassword] = React.useState<string>("");
    const [confirmNewPassword, setConfirmNewPassword] =
        React.useState<string>("");
    const [forgotPasswordCode, setForgotPasswordCode] =
        React.useState<string>("");
    const [isInvalidEmail, setIsInvalidEmail] = React.useState<boolean>(false);

    const [isVerifiedEmailError, setIsVerifiedEmailError] =
        React.useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [verifiedEmailMessage, setVerifiedEmailMessage] =
        React.useState<string>("");
    const [inlineErrorEmailMessage, setinlineErrorEmailMessage] =
        React.useState<any>("");
    const [isPasswordNotUpdated, setIsPasswordNotUpdated] =
        React.useState<boolean>(false);

    const [isNewPasswordDisplayed, setIsNewPasswordDisplayed] =
        React.useState<boolean>(false);
    const [isConfirmedPasswordDisplayed, setIsConfirmedPasswordDisplayed] =
        React.useState<boolean>(false);

    const [isPasswordCorrect, setIsPasswordCorrect] =
        React.useState<boolean>(false);

    const [isPasswordConfirmed, setIsPasswordConfirmed] =
        React.useState<boolean>(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isFocusInInput, setIsFocusInInput] = React.useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [accountDetailInfo, setAccountDetailInfo] = React.useState<any>();

    const {
        index,
        navigation: { next, go },
    } = useStep({ steps: 3 });
    const [isEnterInput, setIsEnterInput] = React.useState<boolean>(false);

    function handleEmailInputOnChangeEvent(
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        setIsEnterInput(true);
        setEmail(e.target.value);

        setIsInvalidEmail(false);
        setIsInvalidEmail(!isEmailValid(e.target.value));
    }

    function handleEmailInputOnPasteEvent(
        e: React.ClipboardEvent<HTMLInputElement>
    ) {
        setEmail(e.clipboardData.getData("Text"));
        setIsInvalidEmail(false);
        setIsInvalidEmail(!isEmailValid(e.clipboardData.getData("Text")));
    }

    async function sendEmailVerificationRequest(email: string) {
        NProgress.start();

        await fetch(`${process.env.REACT_APP_ADMIN_ACCESS}/auth/verify-email`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                email: email.toLowerCase(),
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                NProgress.done();
                if (!data.data.isValid) {
                    if (
                        data.data.statusAccount === "Archiver" ||
                        data.data.statusAccount === "Bloquer"
                    ) {
                        setinlineErrorEmailMessage(
                            t("Merci de vérifiez votre adresse e-mail.")
                        );
                    } else {
                        setinlineErrorEmailMessage("");
                        setIsVerifiedEmailError(true);
                        // setVerifiedEmailMessage(data.message);
                        setinlineErrorEmailMessage(
                            t("Merci de vérifiez votre adresse e-mail.")
                        );
                        setVerifiedEmailMessage("Adresse e-mail invalide");
                    }
                } else {
                    const accountDetailInfo = {
                        commercialName: data.data.commercialname,
                        address: data.data.address,
                    };

                    setAccountDetailInfo(accountDetailInfo);
                    setinlineErrorEmailMessage("");
                    setIsVerifiedEmailError(false);
                    sendResetCodeRequest(email);
                    next();
                }
            })
            .catch((e: any) => {
                console.log(
                    "sendEmailVerificationRequest function : ",
                    e.message
                );
                NProgress.done();
            });
    }

    async function sendResetCodeRequest(email: string) {
        NProgress.start();
        await fetch(
            `${process.env.REACT_APP_ADMIN_ACCESS}/auth/send-reset-code`,
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: email.toLowerCase(),
                    initEmail: initEmail.toLowerCase(),
                }),
            }
        )
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                NProgress.done();
            })
            .catch((e: any) => {
                NProgress.done();
                console.log({ eroorlogin: e.message });
            });
    }

    function handleCodeInputOnChangeEvent(
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        setIsUnverifiedCode(false);
        setForgotPasswordCode(e.target.value);
        setIsInvalidCode(false);
        validateCode(e.target.value);
    }

    function validateCode(codeToValide: string) {
        if (String(codeToValide).length !== FORGOT_PASSWORD_CODE_LENGTH) {
            setIsInvalidCode(true);
        }
    }

    async function sendCodeVerificationRequest(
        email: string,
        forgotPasswordCode: string
    ) {
        NProgress.start();

        await fetch(`${process.env.REACT_APP_ADMIN_ACCESS}/auth/verify-code`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                email: email.toLowerCase(),
                validationCode: forgotPasswordCode,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                NProgress.done();

                if (data.isValid) {
                    setIsUnverifiedCode(false);
                    next();
                } else {
                    setIsUnverifiedCode(true);
                }
            })
            .catch((e: any) => {
                console.log(e.message);
                NProgress.done();
            });
    }

    function checkNewPassword(e: React.ChangeEvent<HTMLInputElement>) {
        setNewPassword(e.target.value);

        if (e.target.value === "") {
            setIsPasswordCorrect(false);
        } else if (
            e.target.value.match(/[0-9]/g) !== null &&
            e.target.value.length > 7 &&
            e.target.value.match(/[a-z]/g) !== null &&
            e.target.value.match(/[A-Z]/g) !== null &&
            e.target.value.match(/[^A-Za-z0-9]/g) !== null
        ) {
            setIsPasswordCorrect(true);
        } else {
            setIsPasswordCorrect(false);
        }

        if (confirmNewPassword !== e.target.value) {
            setIsPasswordConfirmed(false);
        } else if (e.target.value !== "") {
            setIsPasswordConfirmed(true);
            setNewPassword(e.target.value);
        }
    }

    function checkConfirmedPasswords(e: React.ChangeEvent<HTMLInputElement>) {
        setConfirmNewPassword(e.target.value);

        if (newPassword === e.target.value) {
            setIsPasswordConfirmed(true);
            setNewPassword(newPassword);
        } else {
            setIsPasswordConfirmed(false);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async function sendUpdatePasswordRequest(
        email: string,
        newPassword: string,
        forgotPasswordCode: string
    ) {
        NProgress.start();

        await fetch(
            `${process.env.REACT_APP_ADMIN_ACCESS}/auth/update-password`,
            {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    password: newPassword,
                    // email: email.toLowerCase(),
                    initEmail: initEmail.toLowerCase(),
                    validationCode: forgotPasswordCode,
                }),
            }
        )
            .then((response) => response.json())
            .then((data) => {
                NProgress.done();

                if (data.error) {
                    setIsPasswordNotUpdated(false);
                } else {
                    setIsPasswordNotUpdated(true);
                    toggleOpeningForgotPasswordModal();
                }
            })
            .catch(() => {
                NProgress.done();

                return swal({
                    icon: "error",
                    content: <p>{t("There's an error")!}</p>,
                    buttons: false,
                    timer: 2000,
                });
            });
    }

    return (
        <Modal onClosed={() => go(0)} isOpen={isModalOpened} centered>
            <ModalHeader
                toggle={() => {
                    setNewPassword("");
                    setEmail(initEmail);
                    setConfirmNewPassword("");
                    setIsPasswordConfirmed(false);
                    setIsPasswordCorrect(false);
                    setinlineErrorEmailMessage("");
                    setIsVerifiedEmailError(false);
                    setVerifiedEmailMessage("");
                    toggleOpeningForgotPasswordModal();
                    setIsInvalidEmail(false);
                    setIsForgotPasswordClick(false);
                }}
            >
                {t("Forgot your password?")}
            </ModalHeader>

            {index === 0 && (
                <React.Fragment>
                    <ModalBody className="justify-content-center mx-4 my-2">
                        <p className="font-size-14">
                            {t("Please enter your email")}
                        </p>
                        <Input
                            type="email"
                            defaultValue={initEmail}
                            placeholder={t("Grab") as string}
                            onChange={handleEmailInputOnChangeEvent}
                            onPaste={handleEmailInputOnPasteEvent}
                            className={
                                inlineErrorEmailMessage !== "" ? "error" : ""
                            }
                            onFocus={() => {
                                setIsFocusInInput(true);
                            }}
                        />
                        {(!isEnterInput && inlineErrorEmailMessage !== "") ||
                        (!isEnterInput &&
                            isVerifiedEmailError &&
                            email !== "") ? (
                            <span
                                style={{
                                    color: "red",
                                    marginLeft: "5px",
                                    fontSize: "small",
                                }}
                            >
                                {inlineErrorEmailMessage}
                            </span>
                        ) : null}
                    </ModalBody>
                    <ModalFooter className="justify-content-end">
                        <StyledButton
                            variant="primary"
                            outline
                            rounded
                            onClick={() => {
                                setIsInvalidEmail(false);
                                setinlineErrorEmailMessage("");
                                setIsVerifiedEmailError(false);
                                setVerifiedEmailMessage("");
                                toggleOpeningForgotPasswordModal();
                                setIsForgotPasswordClick(false);
                                setEmail("");
                            }}
                            className="w-25"
                        >
                            {t("Return")}
                        </StyledButton>
                        <StyledButton
                            variant="primary"
                            outline={false}
                            rounded
                            disabled={isInvalidEmail}
                            onClick={async () => {
                                setIsFocusInInput(false);
                                setIsInvalidEmail(!isEmailValid(email));
                                setIsEnterInput(false);
                                if (email === "") {
                                    await sendEmailVerificationRequest(
                                        initEmail
                                    );
                                } else {
                                    await sendEmailVerificationRequest(email);
                                }
                                setIsForgotPasswordClick(false);
                            }}
                            className="w-25"
                        >
                            {t("Validate")}
                        </StyledButton>
                    </ModalFooter>
                </React.Fragment>
            )}

            {index === 1 && (
                <React.Fragment>
                    <ModalBody className="justify-content-center mx-4 my-2">
                        <p className="font-size-14">
                            {t(
                                "Enter the 6-digit verification code sent to your email address"
                            )}
                        </p>
                        <Input
                            type="number"
                            placeholder={t("6 digit code") as string}
                            onChange={handleCodeInputOnChangeEvent}
                        />
                        {isInvalidCode ? (
                            <span
                                style={{
                                    color: "red",
                                    marginLeft: "5px",
                                    fontSize: "small",
                                }}
                            >
                                {t("The code must be 6 digit length")}
                            </span>
                        ) : null}
                        {isUnverifiedCode ? (
                            <span
                                style={{
                                    color: "red",
                                    marginLeft: "5px",
                                    fontSize: "small",
                                }}
                            >
                                {t("Sorry, we can't find your code")}.
                            </span>
                        ) : null}
                        <StyledButton
                            //@ts-ignore
                            variant="link"
                            className="pl-0 text-muted"
                            style={{
                                textDecoration: "underline",
                                color: "#495057",
                                background: "transparent",
                            }}
                            onClick={() => {
                                sendResetCodeRequest(email);
                            }}
                        >
                            {t("Resend code")}
                        </StyledButton>
                    </ModalBody>
                    <ModalFooter className="justify-content-end">
                        <StyledButton
                            variant="primary"
                            outline
                            rounded
                            onClick={() => {
                                setIsInvalidEmail(false);
                                setEmail("");
                                setIsInvalidCode(false);
                                setIsForgotPasswordClick(false);
                                toggleOpeningForgotPasswordModal();
                            }}
                            className="w-25"
                        >
                            {t("Return")}
                        </StyledButton>
                        <StyledButton
                            variant="primary"
                            outline={false}
                            rounded
                            disabled={isInvalidCode}
                            onClick={() => {
                                sendCodeVerificationRequest(
                                    // email,
                                    initEmail,
                                    forgotPasswordCode
                                );
                            }}
                            className="w-25"
                        >
                            {t("Validate")}
                        </StyledButton>
                    </ModalFooter>
                </React.Fragment>
            )}

            {index === 2 && (
                <React.Fragment>
                    <ModalBody className="justify-content-center mx-4 my-2">
                        <p className="font-size-14">
                            {t("Please enter your new password")}
                        </p>
                        <div className="new-password-toggle__clz">
                            {isNewPasswordDisplayed ? (
                                <HidePasswordSvgIcon
                                    onClick={() =>
                                        setIsNewPasswordDisplayed(
                                            (prevState: boolean) => !prevState
                                        )
                                    }
                                />
                            ) : (
                                <ShowPasswordSvgIcon
                                    onClick={() =>
                                        setIsNewPasswordDisplayed(
                                            (prevState: boolean) => !prevState
                                        )
                                    }
                                />
                            )}
                        </div>
                        <input
                            type={isNewPasswordDisplayed ? "text" : "password"}
                            className={`form-control ${classnames({
                                "border-danger":
                                    !isPasswordCorrect &&
                                    newPassword !== undefined &&
                                    newPassword !== "",
                                "border-success": isPasswordCorrect,
                            })}`}
                            placeholder={t("New password") as string}
                            onChange={checkNewPassword}
                        />
                        <PasswordStrengthMeter password={newPassword} />
                        <div className="confirmed-password-toggle__clz">
                            {isConfirmedPasswordDisplayed ? (
                                <HidePasswordSvgIcon
                                    onClick={() =>
                                        setIsConfirmedPasswordDisplayed(
                                            (prevState: boolean) => !prevState
                                        )
                                    }
                                />
                            ) : (
                                <ShowPasswordSvgIcon
                                    onClick={() =>
                                        setIsConfirmedPasswordDisplayed(
                                            (prevState: boolean) => !prevState
                                        )
                                    }
                                />
                            )}
                        </div>
                        <input
                            type={
                                isConfirmedPasswordDisplayed
                                    ? "text"
                                    : "password"
                            }
                            className={`form-control mt-3 ${classnames({
                                "border-danger":
                                    !isPasswordConfirmed &&
                                    confirmNewPassword !== "" &&
                                    confirmNewPassword !== undefined,
                                "border-success": isPasswordConfirmed,
                            })}`}
                            placeholder={t("Confirm password") as string}
                            onChange={checkConfirmedPasswords}
                            disabled={!isPasswordCorrect}
                        />

                        {!isPasswordConfirmed &&
                        confirmNewPassword !== "" &&
                        confirmNewPassword !== undefined ? (
                            <span
                                style={{
                                    color: "red",
                                    marginLeft: "5px",
                                    fontSize: "small",
                                }}
                            >
                                {t("Sorry, your passwords not confirmed")}.
                            </span>
                        ) : null}
                        {isPasswordConfirmed && isPasswordNotUpdated ? (
                            <span
                                style={{
                                    color: "red",
                                    marginLeft: "5px",
                                    fontSize: "small",
                                }}
                            >
                                {t("There's an error")}.
                            </span>
                        ) : null}
                    </ModalBody>
                    <ModalFooter className="justify-content-end">
                        <StyledButton
                            variant="primary"
                            rounded
                            outline={false}
                            onClick={() => {
                                setNewPassword("");
                                setConfirmNewPassword("");
                                setIsPasswordConfirmed(false);
                                setIsPasswordCorrect(false);
                                sendUpdatePasswordRequest(
                                    // email,
                                    initEmail,
                                    newPassword,
                                    forgotPasswordCode
                                );
                            }}
                            disabled={
                                !isPasswordConfirmed ||
                                newPassword === "" ||
                                confirmNewPassword === ""
                            }
                            className="w-25"
                        >
                            {t("Validate")}
                        </StyledButton>
                    </ModalFooter>
                </React.Fragment>
            )}
        </Modal>
    );
}
