import React from "react";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import "./index.css";

export function Footer() {
    const { t } = useTranslation();

    return (
        <footer
            className="footer"
            style={{ marginTop: "auto", backgroundColor: "#FFFFFF" }}
        >
            <div className="d-flex col-sm justify-content-center justify-content-sm-start pb-md-row">
                <div className="mt-4 mx-1 mb-md-4 px-4">
                    © {new Date().getFullYear()} AuresKonnect
                </div>

                <Link to="#" className="mt-4 mx-1 mb-md-4 link-div__clz">
                    <u>{t("Terms of use")}</u>
                </Link>

                <Link to="#" className="mt-4 mx-1 mb-md-4 link-div__clz">
                    <u>{t("Confidentiality rules")}</u>
                </Link>
            </div>
        </footer>
    );
}
