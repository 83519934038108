import React from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import NProgress from "nprogress";
import { useTranslation } from "react-i18next";

import "./index.css";

export default function LanguageDropdown() {
    let { pathname } = useLocation();
    const { i18n } = useTranslation();

    const [menu, setMenu] = React.useState<boolean>(false);
    const [language, setLanguage] = React.useState<string>(
        i18n.language === "fr" ? "Français" : "English"
    );

    let matchRegulationModesRoute: boolean = pathname === "/regulation-modes";
    let matchEmployeesRoutes: boolean = pathname.includes("employees");
    let matchStatisticsTurnoverRoute: boolean =
        pathname === "/statistics-turnover";

    function changeLanguage(currentLanguage: string) {
        i18n.changeLanguage(currentLanguage === "en" ? "en" : "fr");

        if (matchRegulationModesRoute || matchEmployeesRoutes) {
        } else if (matchStatisticsTurnoverRoute) {
        } else {
        }

        if (currentLanguage === "en") {
            setLanguage("English");
        } else if (currentLanguage === "fr") {
            setLanguage("Français");
        }
    }

    return (
        <div>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className=" d-inline-block"
            >
                <DropdownToggle
                    className="btn header-item waves-effect"
                    tag="button"
                >
                    <span className="align-middle">
                        {language === "Français" ? (
                            <img
                                alt=""
                                style={{ width: "35px" }}
                                src="https://flagpedia.net/data/flags/h80/fr.webp"
                            ></img>
                        ) : (
                            <img
                                alt=""
                                style={{ width: "40px" }}
                                src="https://flagpedia.net/data/flags/h80/us.webp"
                            ></img>
                        )}
                    </span>
                </DropdownToggle>
                <DropdownMenu className="language-switch" right>
                    <DropdownItem
                        tag="a"
                        style={{ paddingLeft: "21px" }}
                        onClick={async () => {
                            NProgress.start();
                            await changeLanguage(
                                language === "English" ? "fr" : "en"
                            );
                            NProgress.done();
                        }}
                        className={`notify-item ${
                            language === "English" ? "active" : "none"
                        } ${language === "English" ? "d-none" : ""}`}
                    >
                        <span className="align-middle">
                            {" "}
                            <img
                                alt=""
                                style={{ width: "35px" }}
                                src="https://flagpedia.net/data/flags/h80/us.webp"
                            ></img>
                        </span>
                    </DropdownItem>
                    <DropdownItem
                        tag="a"
                        style={{ paddingLeft: "25px" }}
                        onClick={() => changeLanguage("fr")}
                        className={`notify-item ${
                            language === "Français" ? "active" : "none"
                        } ${language === "Français" ? "d-none" : ""}`}
                    >
                        <span className="align-middle">
                            {" "}
                            <img
                                alt=""
                                style={{ width: "35px" }}
                                src="https://flagpedia.net/data/flags/h80/fr.webp"
                            ></img>
                        </span>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}
