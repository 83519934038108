import React from "react";
import { Outlet } from "react-router-dom";

import { Header } from "@components/Header";
import { Footer } from "@components/Footer";
import { CustomErrorBoundary } from "@components/CustomErrorBoundary";

export default function Layout(): JSX.Element {
    return (
        <CustomErrorBoundary>
            <div>
                <Header />
                <React.Suspense fallback="Loading...">
                    <Outlet />
                </React.Suspense>
                <Footer />
            </div>
        </CustomErrorBoundary>
    );
}
