import React from "react";
import { useRoutes } from "react-router-dom";

import { routes } from "@routes/index";

export function Router(): React.ReactElement<
    // eslint-disable-next-line
    any,
    // eslint-disable-next-line
    string | React.JSXElementConstructor<any>
> | null {
    return useRoutes(routes);
}
