import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import "./index.css";

type PropsType = {
    title: string;
    description: string;
    link: string;
    img: string;
    windowsDownloadLink: string;
    secondTitle: string;
    allDescriptions: string[];
};

export function PortalCard({
    title,
    description,
    img,
    allDescriptions,
    secondTitle,
}: PropsType) {
    const { t } = useTranslation();
    // const { mainColor } = useSnapshot(store);

    return (
        <React.Fragment>
            <div
                style={{ backgroundColor: "rgb(0, 0, 0)" }}
                className={`${classnames("installer-outer_clz")}`}
            >
                <div className="installer-content__clz">
                    <div className="installer-h1-title__clz">{t(title)}</div>
                    <div style={{ paddingLeft: "15px" }}>
                        {" "}
                        <div className="installer-second-title__clz">
                            {t(secondTitle)}
                        </div>
                        <div className="installer-p-title__clz">
                            {t(description)}
                        </div>
                        <div>
                            {allDescriptions.map((el: any) => (
                                <div>
                                    <div className="main-container__clz">
                                        <div className="rounded-point__clz"></div>
                                        <div className="text__clz">{t(el)}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="installer-image__clz">
                    <img src={img} className="img" alt="" />
                </div>
            </div>
        </React.Fragment>
    );
}
